<template>
  <div class="videoBox" :id="id"></div>
</template>

<script>
import Player, { Flv } from 'xgplayer'
import { FlvPlugin, EVENT } from 'xgplayer-flv'
import 'xgplayer/dist/index.min.css'
export default {
  name: 'flvCom',
  data() {
    return {
      player: null,
      url: '',
    }
  },
  created() {
    this.destroyPlayer()
  },
  mounted() {},
  beforeDestroy() {
    this.destroyPlayer()
  },
  props: {
    id: {
      type: String,
      default: 'videoID',
    },
  },
  methods: {
    // 初始化
    initPlayer() {
      if (FlvPlugin.isSupported()) {
        this.player = new Player({
          id: this.id,
          url: this.url,
          isLive: true,
          plugins: [FlvPlugin],
          ignores: ['volume', 'cssfullscreen'],
          //初始化显示视频首帧
          videoInit: true,
          //音量(自动播放需配合静音)
          volume: 0,
          //循环播放
          loop: true,
          autoplay: true,
          autoplayMuted: true,
          playbackRate: false,
          flv: {
            retryCount: 999, // 重试 3 次，默认值
            // 每次重试间隔 1 秒，默认值
            retryDelay: 500,
            loadTimeout: 5000, // 请求超时时间为 10 秒，默认值
            // 直播目标延迟，默认 5 秒
            targetLatency: 0.5,
            // 直播允许的最大延迟，默认 10 秒
            maxLatency: 1,
            // 直播断流时间，默认 0 秒，（独立使用时等于 maxLatency）
            disconnectTime: 0,
          },
        })
        this.listeningPlayer()
      }
    },
    // 替换视频
    switchURLPlayer(url) {
      this.url = url
      this.player.switchURL(url)
    },
    // 销毁视频
    destroyPlayer() {
      if (!!this.player) {
        console.log('销毁')
        try {
          this.player.destroy()
        } catch (e) {
          console.log('出错', e)
        } finally {
          this.player = null
        }
      }
    },
    // 监听
    listeningPlayer() {
      this.player.on('core_event', ({ eventName, ...rest }) => {
        // eventName: flv 事件名; rest: flv 事件回调函数参数
        // console.log('eventName', eventName)
        // console.log('this.player', this.player)
        if (eventName == 'core.loadretry') {
          console.log('重新加载视频')
          // this.player.plugins.flv.load(this.url)
          this.player.reload()
        }
      })
      // // 错误监听
      this.player.on(EVENT.ERROR, (error) => {
        // xgplayer 中的 Errors 对象
        console.log('id', this.id)
        console.log('url', this.url)
        console.log('error', error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.videoBox {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
}
</style>
